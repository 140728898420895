import { Canvas, useFrame } from '@react-three/fiber'
import {
  Environment,
  RandomizedLight,
  AccumulativeShadows,
  OrbitControls
} from '@react-three/drei'
import { Shirt } from './Shirt_baked'

export const App = () => (
  <Canvas shadows camera={{ position: [5, 2, 10], fov: 15 }}>
    <ambientLight intensity={0.20} />
    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
    <pointLight position={[-10, -5, -10]} />
    <group position={[0, 0, 0]}>
      <Shirt scale={3} />
      <AccumulativeShadows frames={80} color="black" opacity={1} scale={12} position={[0, 0.04, 0]}>
        <RandomizedLight amount={8} radius={5} ambient={0.5} position={[5, 5, -10]} bias={0.001} />
      </AccumulativeShadows>
    </group> 
    <Environment preset="city" resolution={512} />
    <OrbitControls />

  </Canvas>
)

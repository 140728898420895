import React, { useRef, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF, useTexture, Decal } from '@react-three/drei';
import { useControls } from 'leva';

// Define a list of client logos with their dimensions and colors
const clientLogos = {
  'Three.js': { url: '/three.png', width: 329, height: 338, color: '#5102EE' },
  'FedEx': { url: '/fedex.png', width: 347, height: 94, color: '#FF5900' },
  'PayPal': { url: '/paypal.png', width: 797, height: 195, color: '#253B80' },
  'CocaCola': { url: '/cocacola.png', width: 256, height: 78, color: '#E61D2A' },
  // Add more logos and their respective colors as needed
};

export function Shirt(props) {
  const meshRef = useRef();
  const { nodes, materials } = useGLTF('./shirt_baked.glb');
  const badge = useTexture('/b_logo.png');

  const [decalSettings, setDecalSettings] = useState({ scale: [0.20, 0.020, 0.20] }); // default settings
  const [shirtColor, setShirtColor] = useState('#4225D4'); // initial shirt color

  // Leva controls for logo selection
  const { selectedLogoUrl } = useControls({
    selectedLogoUrl: {
      options: Object.fromEntries(Object.entries(clientLogos).map(([k, v]) => [k, v.url])),
      value: clientLogos['Three.js'].url // Default logo
    }
  });

  const logo = useTexture(selectedLogoUrl);

  // Update the decal settings and shirt color when the selected logo changes
  useEffect(() => {
    const selectedLogo = Object.values(clientLogos).find(logo => logo.url === selectedLogoUrl);
    if (selectedLogo) {
      const aspectRatio = selectedLogo.width / selectedLogo.height;
      const maxDecalWidth = 0.17;
      const maxDecalHeight = 0.17;
      let decalWidth, decalHeight;

      if (aspectRatio > 1) { // Landscape
        decalWidth = Math.min(maxDecalWidth, aspectRatio * maxDecalHeight);
        decalHeight = decalWidth / aspectRatio;
      } else { // Portrait
        decalHeight = Math.min(maxDecalHeight, maxDecalWidth / aspectRatio);
        decalWidth = decalHeight * aspectRatio;
      }

      setDecalSettings({
        scale: [-decalWidth, decalHeight, 0.13],
      });

      // Update shirt color based on selected logo
      setShirtColor(selectedLogo.color);
    }
  }, [selectedLogoUrl]);

  // Update the rotation on each frame
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.007;
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={meshRef} geometry={nodes.Object_4.geometry} material={materials.lambert1} material-color={shirtColor}>
        <Decal position={[0.077, 0.077, 0.13]} rotation={0} scale={[-0.035, 0.035, 0.035]} map={badge} map-anisotropy={16}/>
        <Decal position={[0, -0.05, 0.13]} rotation={0} scale={decalSettings.scale} map={logo} map-anisotropy={16} />
      </mesh>
    </group>
  );
}

useGLTF.preload('./shirt_baked.glb');
useTexture.preload('/b_logo.png');
// Preload all client logos
Object.values(clientLogos).forEach(logo => useTexture.preload(logo.url));
